import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { useAuth } from 'contexts/authContext';
import Header from 'components/Header';
import { api } from 'api';
import { alert } from 'utils/sweetalert2';
import { Table } from 'components/Table';

const Container = styled.div`
  padding: 1rem;
`;

const ContainerTextField = styled.div`
  display: grid;
  grid-gap: 1rem;
  max-width: 30rem;
`;

const StyledTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 19px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-contained.Mui-disabled {
    background-color: gray;
  }
  & {
    width: 10rem;
    background-color: #179fe2;
  }
`;

const StyledTextArea = styled(TextField)`
  textarea {
    height: 100%;
  }

  .MuiOutlinedInput-root {
    height: 160px;
  }
`;

const headerList = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Terms English',
    field: 'terms_en',
  },
  {
    label: 'Terms Thai',
    field: 'terms_th',
  },
  {
    label: 'Version',
    field: 'version',
  },
  {
    label: 'Version Text',
    field: 'version_text',
  },
];

export default function TermsAndConditions() {
  const {
    state: { token },
  } = useAuth();
  const [clientAppId, setClientAppId] = useState('');
  const [termsEn, setTermsEn] = useState('');
  const [termsTh, setTermsTh] = useState('');
  const [versionText, setVersionText] = useState('');
  const [termsAndConditionsList, setTermsAndConditionsList] = useState([]);

  useEffect(() => {
    getTermsAndConditionsList();
  }, []);

  const getTermsAndConditionsList = async () => {
    try {
      const { data } = await api.getTermsAndConditionsList({
        limit: 10,
        offset: 0,
        staff_user_token: token,
      });
      if (data.code !== 0) {
        console.error(data.message);
      } else {
        const { terms_and_conditions_list } = data.data;
        setTermsAndConditionsList(terms_and_conditions_list);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        alert(error.response.data.message);
      }
    }
  };

  const handleAddClientApp = async () => {
    try {
      const { data } = await api.createTermsAndConditions({
        client_app_id: clientAppId,
        staff_user_token: token,
        terms_en: termsEn,
        terms_th: termsTh,
        version_text: versionText,
      });
      if (data.code !== 0) {
        console.error(data.message);
      } else {
        getTermsAndConditionsList();
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        await alert({
          timer: 0,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          title: 'Unable to add terms and conditions!',
          text: error.response.data.message,
        });
      }
    }
  };

  return (
    <Container>
      <Header title="Terms And Conditions" />
      <ContainerTextField>
        <StyledTextField
          variant="outlined"
          fullWidth
          size="small"
          label={'Client App ID'}
          onChange={(e) => setClientAppId(e.target.value)}
          value={clientAppId}
          required
        />
        <StyledTextArea
          variant="outlined"
          label={'Terms English'}
          multiline
          rows={4}
          value={termsEn}
          onChange={(e) => setTermsEn(e.target.value)}
          required
        />
        <StyledTextArea
          variant="outlined"
          label={'Terms Thai'}
          multiline
          rows={4}
          value={termsTh}
          onChange={(e) => setTermsTh(e.target.value)}
          required
        />
        <StyledTextField
          variant="outlined"
          fullWidth
          size="small"
          label={'Version'}
          onChange={(e) => setVersionText(e.target.value)}
          value={versionText}
          required
        />
        <StyledButton
          variant="contained"
          disabled={!clientAppId || !termsEn || !termsTh || !versionText}
          onClick={handleAddClientApp}
        >
          Add
        </StyledButton>
      </ContainerTextField>
      <Table headerList={headerList} dataList={termsAndConditionsList}></Table>
    </Container>
  );
}
