import axios from 'axios';

axios.defaults.timeout = 600000;

// export function setAuthorizationToken(token) {
//   if (token) {
//     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   } else {
//     delete axios.defaults.headers.common.Authorization;
//   }
// }

export default axios;
