import React from 'react';
import styled from 'styled-components';
import { color } from 'assets/theme';
import { Paper, Typography, IconButton } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const StyledPaper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  min-height: 56px;
`;

const StyledTextTitle = styled(Typography)`
  &&& {
    font-weight: 700;
    height: 20px;
  }
`;

const StyledTextSubTitle = styled(Typography)`
  &&& {
    margin-top: 0.5rem;
    color: ${({ type }) => (type === 'blue' ? color.primary : color.gray)};
    height: 16px;
    font-weight: 500px;
  }
`;

const StyledIconButton = styled(IconButton)`
  &&& {
    padding: 0;
    width: 2rem;
    height: 2rem;
    margin-right: 16px;
    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
      color: ${color.gray};
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function Header({
  canGoBack,
  title,
  subTitle,
  subTitleType,
  button,
  handleClickBack,
}) {
  const history = useHistory();

  return (
    <StyledPaper>
      <TitleContainer>
        {canGoBack && (
          <StyledIconButton onClick={handleClickBack}>
            <ArrowBackIos />
          </StyledIconButton>
        )}
        <div>
          <StyledTextTitle>{title}</StyledTextTitle>
          {subTitle && (
            <StyledTextSubTitle variant="subtitle2" type={subTitleType}>
              {subTitle}
            </StyledTextSubTitle>
          )}
        </div>
      </TitleContainer>
      <div>{button}</div>
    </StyledPaper>
  );
}
