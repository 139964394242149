import React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
// import MuiPagination from '@mui/material/Pagination'
// import { palette } from 'styles/palette'

const Container = styled.div`
  overflow-x: auto;
  width: 100%;
  padding-bottom: 1rem;
`;

const CustomTable = styled.table`
  table-layout: auto;
  width: 100%;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  border-collapse: collapse;
  margin-top: 2rem;

  thead > tr > th {
    text-align: start;
    font-size: 1rem;
    padding: 1rem;
  }

  tbody > tr {
    cursor: ${(props) => props.cursor};
  }

  tbody > tr > td {
    text-align: start;
    font-size: 1rem;
    padding: 1rem;
    vertical-align: text-top;
  }
`;

const Thead = styled.thead`
  color: black;
  height: 2.5rem;
  border-bottom: 1px solid gray;
`;

const Tbody = styled.tbody`
  color: black;

  tr:not(:first-of-type) {
    border-top: 1px solid gray;
  }

  tr:hover {
    /* background-color: 'rgba(62, 63, 63, 0.3)'; */
  }
`;

/* const Pagination = styled(MuiPagination)`
  margin-top: 2rem;

  & .MuiPagination-ul {
    justify-content: center;
    gap: 0.5rem;
  }
  li {
    background-color: ${palette.divider};
    border-radius: 5px;
  }
  li > button {
    color: white;
    opacity: unset;
    margin: 0;
    padding: 0;
  }
  li > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
    height: 2rem;
    margin: 0;
    padding: 0 0 3px 0;
  }
`; */

const pageSize = 10;

export const Table = ({
  headerList,
  dataList,
  handleClickRow,
  page,
  count,
  handlePageChange,
}) => {
  const [limitPage, setLimitPage] = useState(0);

  useEffect(() => {
    const limitPage = Math.ceil(count / pageSize);
    setLimitPage(limitPage);
  }, [count]);

  return (
    <>
      <Container>
        <CustomTable cursor={handleClickRow ? 'pointer' : 'default'}>
          <Thead>
            <tr>
              {headerList.map((header) => {
                return <th key={header.field}>{header.label}</th>;
              })}
            </tr>
          </Thead>
          <Tbody>
            {dataList.map((data, indexRow) => {
              return (
                <tr
                  key={`tr-${indexRow}`}
                  // onClick={() => {
                  //   handleClickRow ? handleClickRow(data) : {};
                  // }}
                >
                  {headerList.map((header, index) => {
                    return (
                      <td key={`td-${indexRow}-${index}`}>
                        {data[header.field]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </Tbody>
        </CustomTable>
      </Container>
      {/* <Pagination
        color="primary"
        count={limitPage}
        page={page}
        shape="rounded"
        onChange={handlePageChange}
      /> */}
    </>
  );
};
