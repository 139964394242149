import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useAuth } from 'contexts/authContext';
import { useHistory } from 'react-router-dom';
import { alert } from 'utils/sweetalert2';

import {
  Paper,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { api } from 'api';

export const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    background: linear-gradient(
    207deg,
    rgba(0, 192, 242, 1) 0%,
    rgba(0, 97, 155, 1) 100%
  );
  }
`;

const StyledContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  place-content: center;
`;

const StyledContainerPaperForm = styled(Paper)`
  padding: 3.5rem;
  width: 28vw;
  max-width: 24rem;
  &.MuiPaper-rounded {
    border-radius: 20px;
  }
`;

const StyledGridForm = styled.div`
  display: grid;
  grid-template-rows: auto;
  font-weight: bold;
  grid-gap: 1rem;
`;

const StyledButton = styled(Button)`
  &&& {
    background-color: #179fe2;
  }
`;

const StyledTextLogin = styled.div`
  /* text-align: center; */
`;

const StyledTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 19px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

export default function Login() {
  const {
    state: { isAuthenticated },
    dispatch,
  } = useAuth();

  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, []);

  const handleLogin = async () => {
    try {
      const { data } = await api.getStaffSession({ username, password });
      if (data.code !== 0) {
        console.error(data.message);
      } else {
        const { staff_user_token } = data.data;
        dispatch({
          type: 'SET_AUTHENTICATED',
          payload: {
            isAuthenticated: true,
            token: staff_user_token,
            user: {},
          },
        });
        history.push('/');
        alert({
          toast: true,
          timer: 3000,
          icon: 'success',
          title: 'Signed in successfully',
          position: 'bottom-end',
        });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        await alert({
          timer: 0,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          title: 'Unable to sign in!',
          text: error.response.data.message,
        });
      }
    }
  };

  return (
    <>
      <GlobalStyles />
      <StyledContainer>
        <StyledContainerPaperForm>
          <form noValidate>
            <StyledGridForm>
              <StyledTextLogin>Sign in</StyledTextLogin>
              <StyledTextField
                id="textfield-username"
                variant="outlined"
                fullWidth
                size="small"
                name="username"
                label={'Username'}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
              <StyledTextField
                id="textfield-password"
                variant="outlined"
                fullWidth
                size="small"
                label={'Password'}
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <StyledButton variant="contained" fullWidth onClick={handleLogin}>
                Sign in
              </StyledButton>
            </StyledGridForm>
          </form>
        </StyledContainerPaperForm>
      </StyledContainer>
    </>
  );
}
