import axios from './axios_config';

const baseURL = process.env.REACT_APP_BASE_URL;

export const post = async (url, payload, config) => {
  try {
    const response = await axios.post(`${baseURL}${url}`, payload, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const get = async (url) => {
  try {
    const response = await axios.get(`${baseURL}${url}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const api = {
  getStaffSession: (payload) => post('/get_staff_session', payload),
  addClientApp: (payload) => post('/add_client_app', payload),
  getClientAppList: (payload) => post('/get_client_app_list', payload),
  createTermsAndConditions: (payload) =>
    post('/create_terms_and_conditions', payload),
  generateOtpForRegisteringTrustedDevice: (payload) =>
    post('/generate_otp_for_registering_trusted_device', payload),
  getTrustedDeviceList: (payload) => post('/get_trusted_device_list', payload),
  getTermsAndConditionsList: (payload) =>
    post('/get_terms_and_conditions_list', payload),
};
