import React, {
  useState,
  useMemo,
  useCallback,
  Fragment,
  useEffect,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useAuth } from 'contexts/authContext';
import styled from 'styled-components';
import { color } from 'assets/theme';
import { useLocation, useHistory } from 'react-router-dom';
import { useLayout } from '../context';
import {
  Tabs,
  Tab,
  Drawer,
  Toolbar,
  ListItem,
  ListItemText,
  Collapse,
  List,
  Paper,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
export const drawerWidth = 200;

const listTabMenu = [
  {
    label: 'Client App',
    path: '/',
    subMenus: [],
  },
  {
    label: 'Terms and Conditions',
    path: '/terms-and-conditions',
    subMenus: [],
  },
  {
    label: 'Trusted Devices',
    path: '/trusted-devices',
    subMenus: [],
  },
];

const mapPathName = {};

const StyledContent = styled.div`
  padding-top: 56px;
  width: calc(100vw - 248px);
  flex-grow: 1;
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: ${drawerWidth}px;
    background-color: #292e3d;
    color: ${color.gray};
    padding-top: 2.5rem;
  }
  width: ${drawerWidth - 4}px;
`;

const StyledTabs = styled(Tabs)`
  .PrivateTabIndicator-root-1 {
    left: 0;
    width: 3px;
  }
  .PrivateTabIndicator-colorSecondary-3 {
    background: linear-gradient(118deg, #27f5f5, #1182e8);
  }
`;

const StyledListItem = styled(ListItem)`
  &&& {
    padding-left: ${(props) =>
      props.padding_left ? props.padding_left : '2rem'};
  }
  &.MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: #4e5361;
    color: white;
  }
`;

const StyledTab = styled(Tab)`
  &&& {
    padding-left: 1.5rem;
  }
  &.Mui-selected {
    background-color: #4e5361;
    color: white;
  }
  &.MuiTab-root {
    padding: 0;
  }
`;

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-body1 {
    font-size: 0.85rem;
  }
`;

const StyledSpan = styled.span`
  background: linear-gradient(118deg, #27f5f5, #1182e8);
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
`;

const Content = styled(Paper)`
  margin: 1rem;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function MuiDrawer({ children }) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const {
    state: { drawerOpen },
  } = useLayout();
  const {
    state: { user },
  } = useAuth();

  const [collapseSubMenu, setCollapseSubMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('/');
  const selectedMenuByPathname = useCallback(() => {
    const splitedPathname = location.pathname.split('/');
    if (splitedPathname[1]) {
      if (mapPathName[splitedPathname[1]]) {
        if (mapPathName[splitedPathname[1]].roles.length === 0) {
          setSelectedMenu(mapPathName[splitedPathname[1]].path);
        } else if (mapPathName[splitedPathname[1]].roles.includes(user?.role)) {
          setSelectedMenu(mapPathName[splitedPathname[1]].path);
        } else {
          setSelectedMenu('/' + location.pathname.split('/')[1]);
        }
      } else {
        setSelectedMenu('/' + location.pathname.split('/')[1]);
      }
    } else {
      setSelectedMenu('/');
    }
  }, [location.pathname, user]);

  useEffect(() => {
    selectedMenuByPathname();
  }, [selectedMenuByPathname]);

  const getRedirectPath = useCallback(
    (path) => {
      const username = user?.username;
      const role = user?.role;
      const userOrganizationId = user?.organization?.id;
      const redirectUrl = listTabMenu.find(
        (menu) =>
          menu.path === path &&
          (menu?.roles == null || menu?.roles?.includes(role))
      );

      if (redirectUrl?.getPathFn) {
        return redirectUrl.getPathFn({ username, userOrganizationId });
      } else {
        return redirectUrl.path;
      }
    },
    [user]
  );

  const handleMenuChange = useCallback(
    (newValue, subMenu = false) => {
      let url = newValue;
      if (!subMenu) {
        url = getRedirectPath(newValue);
      }
      history.push(url);
    },
    [getRedirectPath, history]
  );

  const getListMenu = useMemo(() => {
    const role = user?.role;
    return listTabMenu.map((menu, index) => {
      if (menu.roles == null || menu.roles.includes(role)) {
        if (menu.subMenus.length === 0) {
          return (
            <StyledListItem
              key={menu.label}
              button
              selected={selectedMenu === menu.path}
              onClick={() => handleMenuChange(menu.path)}
            >
              {selectedMenu === menu.path && <StyledSpan />}
              <StyledListItemText primary={menu.label} />
            </StyledListItem>
          );
        } else {
          return (
            <Fragment key={menu.label}>
              <StyledListItem
                button
                onClick={() => setCollapseSubMenu((prev) => !prev)}
                selected={selectedMenu === '/user'}
              >
                <StyledListItemText primary={menu.label} />
                {collapseSubMenu ? <ExpandLess /> : <ExpandMore />}
              </StyledListItem>
              <Collapse in={collapseSubMenu} timeout="auto" unmountOnExit>
                {menu.subMenus.map((subMenu) => (
                  <StyledListItem
                    key={subMenu.label}
                    padding_left={'3rem'}
                    button
                    selected={selectedMenu === subMenu.path}
                    onClick={() => handleMenuChange(subMenu.path, true)}
                  >
                    {selectedMenu === subMenu.path && <StyledSpan />}
                    <StyledListItemText primary={subMenu.label} />
                  </StyledListItem>
                ))}
              </Collapse>
            </Fragment>
          );
        }
      } else {
        return null;
      }
    });
  }, [collapseSubMenu, handleMenuChange, selectedMenu, user]);

  return (
    <>
      <StyledDrawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <List>{getListMenu}</List>
      </StyledDrawer>
      <StyledContent
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Content>{children}</Content>
      </StyledContent>
    </>
  );
}
