import Swal from 'sweetalert2';

export async function alert({
  toast = false,
  timerProgressBar = false,
  position = 'center',
  timer = 0,
  icon = 'success',
  title,
  text,
  allowOutsideClick = true,
  html,
  showConfirmButton,
  showCancelButton = false,
  confirmButtonText,
  cancelButtonText,
}) {
  return Swal.fire({
    toast,
    position,
    showConfirmButton,
    timer,
    icon,
    title,
    text,
    ...{ ...(toast ? {} : { allowOutsideClick }) },
    html,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
  });
}
