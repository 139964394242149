import React, { createContext, useContext, useReducer } from 'react';

const LayoutContext = createContext();
const initialState = {
  drawerOpen: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DRAWER_OPEN':
      return { ...state, drawerOpen: action.payload };
    default:
      return;
  }
};

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
