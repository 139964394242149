import React from 'react';
import { Switch, Route } from 'react-router';
import { useAuth } from 'contexts/authContext';

import Layout from 'components/Layout';
import Login from 'pages/Login';
import ClientApp from 'pages/ClientApp';
import TermsAndConditions from 'pages/TermsAndConditions';
import TrustedDevices from 'pages/TrustedDevices';

export default function RouterView() {
  const {
    state: { isAuthenticated },
  } = useAuth();

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      {isAuthenticated && (
        <Layout>
          <Switch>
            <Route exact path="/" component={ClientApp} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route exact path="/trusted-devices" component={TrustedDevices} />
          </Switch>
        </Layout>
      )}
    </Switch>
  );
}
