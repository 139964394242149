import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

const authContext = createContext();

const initialState = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  token: localStorage.getItem('token') ?? null,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTHENTICATED':
      localStorage.setItem('token', action.payload.token);
      return { ...state, ...action.payload };
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'LOGOUT':
      localStorage.removeItem('token');
      return { ...state, isAuthenticated: false, token: null, user: null };
    default:
      return;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false);
        history.push('/login');
      } else {
        try {
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      }
    })();
  }, [state.isAuthenticated]);

  return (
    <authContext.Provider value={{ state, dispatch }}>
      {!loading && children}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext);
