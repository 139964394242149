import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { useAuth } from 'contexts/authContext';
import Header from 'components/Header';
import { Table } from 'components/Table';
import { api } from 'api';
import dayjs from 'dayjs';
import QRCode from 'qrcode.react';
import { alert } from 'utils/sweetalert2';

const Container = styled.div`
  padding: 1rem;
`;

const ContainerTextField = styled.div`
  display: grid;
  grid-gap: 1rem;
  max-width: 30rem;
`;

const StyledTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 19px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-contained.Mui-disabled {
    background-color: gray;
  }
  & {
    width: 10rem;
    background-color: #179fe2;
  }
`;

const ContainerResult = styled.div`
  margin-top: 2rem;
`;

const headerList = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Model',
    field: 'model',
  },
  {
    label: 'Operating System',
    field: 'operating_system',
  },
  {
    label: 'Public Key Pem',
    field: 'public_key_pem',
  },
];

export default function TrustedDevices() {
  const {
    state: { token },
  } = useAuth();
  const [clientAppId, setClientAppId] = useState('');
  const [result, setResult] = useState(null);
  const [trustedDeviceList, setTrustedDeviceList] = useState([]);

  useEffect(() => {
    getTrustedDeviceList();
  }, []);

  const getTrustedDeviceList = async () => {
    try {
      const { data } = await api.getTrustedDeviceList({
        limit: 10,
        offset: 0,
        staff_user_token: token,
      });
      if (data.code !== 0) {
        console.error(data.message);
      } else {
        const { trusted_device_list } = data.data;
        setTrustedDeviceList(trusted_device_list);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        await alert({
          timer: 0,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          title: 'Unable to get trusted device list!',
          text: error.response.data.message,
        });
      }
    }
  };

  const handleAddClientApp = async () => {
    try {
      const { data } = await api.generateOtpForRegisteringTrustedDevice({
        client_app_id: clientAppId,
        staff_user_token: token,
      });
      if (data.code !== 0) {
        console.error(data.message);
      } else {
        const result = data.data;
        setResult(result);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        if (error?.response?.data) {
          await alert({
            timer: 0,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'OK',
            title: 'Unable to generate OTP!',
            text: error.response.data.message,
          });
        }
      }
    }
  };

  return (
    <Container>
      <Header title="Trusted Devices" />
      <ContainerTextField>
        <StyledTextField
          variant="outlined"
          fullWidth
          size="small"
          label={'Client App ID'}
          onChange={(e) => setClientAppId(e.target.value)}
          value={clientAppId}
          required
        />

        <StyledButton
          variant="contained"
          disabled={!clientAppId}
          onClick={handleAddClientApp}
        >
          Add
        </StyledButton>
      </ContainerTextField>
      {result && (
        <ContainerResult>
          Result:
          <div>
            Expire At: {dayjs(result.expire_at).format('DD-MM-YYYY HH:mm:ss')}
          </div>
          <div>OTP: {result.otp}</div>
          <QRCode value={result.otp} renderAs="svg" />
        </ContainerResult>
      )}
      <Table headerList={headerList} dataList={trustedDeviceList}></Table>
    </Container>
  );
}
