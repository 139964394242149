import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { useAuth } from 'contexts/authContext';
import Header from 'components/Header';
import { Table } from 'components/Table';
import { api } from 'api';
import { alert } from 'utils/sweetalert2';

const Container = styled.div`
  padding: 1rem;
`;

const ContainerTextField = styled.div`
  display: grid;
  grid-gap: 1rem;
  max-width: 30rem;
`;

const StyledTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 19px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-contained.Mui-disabled {
    background-color: gray;
  }
  & {
    width: 10rem;
    background-color: #179fe2;
  }
`;

const headerList = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Client App ID',
    field: 'app_id',
  },
  {
    label: 'App Secret',
    field: 'app_secret',
  },
  {
    label: 'Callback URL',
    field: 'callback_url',
  },
];

export default function ClientApp() {
  const {
    state: { token },
  } = useAuth();
  const [name, setName] = useState('');
  const [callbackUrl, setCallbackUrl] = useState('');
  const [clientAppList, setClientAppList] = useState([]);

  useEffect(() => {
    getClientAppList();
  }, []);

  const getClientAppList = async () => {
    try {
      const { data } = await api.getClientAppList({
        limit: 10,
        offset: 0,
        staff_user_token: token,
      });
      if (data.code !== 0) {
        console.error(data.message);
      } else {
        const { client_app_list } = data.data;
        setClientAppList(client_app_list);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        alert(error.response.data.message);
      }
    }
  };

  const handleAddClientApp = async () => {
    try {
      const { data } = await api.addClientApp({
        name,
        callback_url: callbackUrl,
        staff_user_token: token,
      });
      if (data.code !== 0) {
        console.error(data.message);
      } else {
        getClientAppList();
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        await alert({
          timer: 0,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          title: 'Unable to add client app!',
          text: error.response.data.message,
        });
      }
    }
  };

  return (
    <Container>
      <Header title="Client App" />
      <ContainerTextField>
        <StyledTextField
          required
          id="name"
          variant="outlined"
          fullWidth
          size="small"
          name="Name"
          label={'Name'}
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <StyledTextField
          required
          id="callback-url"
          variant="outlined"
          fullWidth
          size="small"
          label={'Callback URL'}
          name="Callback URL"
          onChange={(e) => setCallbackUrl(e.target.value)}
          value={callbackUrl}
        />
        <StyledButton
          disabled={!name || !callbackUrl}
          variant="contained"
          onClick={handleAddClientApp}
        >
          Add
        </StyledButton>
      </ContainerTextField>
      <Table headerList={headerList} dataList={clientAppList}></Table>
    </Container>
  );
}
