import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/authContext';
import { Link } from 'react-router-dom';
import { useLayout } from '../context';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowDropDown, Menu as MenuIcon } from '@material-ui/icons';

export const appBarHeight = '3.5rem';

const StyledAppBar = styled(MuiAppBar)`
  &&& {
    background-color: white;
    color: black;
    z-index: 1201;
    box-shadow: none;
    border-bottom: solid 2px #f6f6f8;
    height: ${appBarHeight};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledContainerUserInfo = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-gap: 0.75rem;
  align-items: center;
  div:nth-child(2) {
    max-width: 20rem;
  }
`;

const StyledContainerIconAppBar = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 1.5rem;
  align-items: center;
  margin-right: 1.5rem;
`;

const StyledAvatar = styled(Avatar)`
  &&& {
    width: 2rem;
    height: 2rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  &&& {
    padding: 0;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  width: 4.5rem;
  height: 2.5rem;
  margin-left: 0.5rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextOrganizationName = styled(Typography)`
  &&& {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const StyledToolBar = styled(Toolbar)`
  @media (min-width: 600px) {
    &&& {
      .MuiToolbar-regular .MuiToolbar-root {
        height: 32px;
      }
    }
  }
`;

export default function AppBar() {
  const history = useHistory();
  const {
    state: { drawerOpen },
    dispatch: dispatchLayout,
  } = useLayout();

  const {
    state: { user },
    dispatch,
  } = useAuth();

  const matches = useMediaQuery('(min-width:860px)');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatchLayout({ type: 'SET_DRAWER_OPEN', payload: matches });
  }, [dispatchLayout, matches]);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch({
      type: 'LOGOUT',
      payload: {},
    });
  };

  return (
    <StyledAppBar position="fixed">
      <StyledContainer>
        <StyledToolBar>
          <IconButton
            color="inherit"
            onClick={() => {
              dispatchLayout({ type: 'SET_DRAWER_OPEN', payload: !drawerOpen });
            }}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <div>IDEN Back Office</div>
        </StyledToolBar>
        <StyledToolBar>
          <StyledContainerUserInfo>
            <StyledAvatar
              // alt={user?.username ?? '-'}
              src="fallback"
            ></StyledAvatar>
            <FlexColumn>
              <Typography variant="subtitle2">{'Admin'}</Typography>
              <StyledTextOrganizationName variant="subtitle2">
                {user?.organization?.name ?? ''}
              </StyledTextOrganizationName>
            </FlexColumn>
            <StyledIconButton onClick={handleClickMenu}>
              <ArrowDropDown />
            </StyledIconButton>
          </StyledContainerUserInfo>
        </StyledToolBar>
      </StyledContainer>
      <Menu
        id="appBarMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Sign out</MenuItem>
      </Menu>
    </StyledAppBar>
  );
}
