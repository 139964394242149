import React from 'react';
import styled from 'styled-components';
import { LayoutProvider } from './context';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';

const Container = styled.div`
  display: flex;
`;

export default function Layout({ children }) {
  return (
    <LayoutProvider>
      <Container>
        <AppBar />
        <Drawer>{children}</Drawer>
      </Container>
    </LayoutProvider>
  );
}
