import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'contexts/authContext';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { GlobalStyles } from 'assets/theme/globalstyles';
import theme from 'assets/theme';
import RouterView from 'router';

export default function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <AuthProvider>
            <RouterView />
          </AuthProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </StylesProvider>
  );
}
